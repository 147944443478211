.feed-layout {
  position: relative;
}

.feed-layout:after {
  content: '';
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  background: rgb(240, 240, 240);
  width: 50vw;
  left: 100%;
}

.arrow-icon,
.arrow-icon--reversed {
  transition: all 150ms ease-in-out;
}

.arrow-button:hover .arrow-icon {
  transform: translateX(4px);
}

.arrow-button:hover .arrow-icon--reversed {
  transform: translateX(-4px);
}

.WaveGlass__Container {
  --size: 210px;
  --stroke: 20px;
  --glass-size: 160px;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.WaveGlass__Progress {
  position: absolute;
}

.WaveGlass {
  position: absolute;
  display: flex;
  width: var(--glass-size);
  height: var(--glass-size);
  clip-path: circle(calc(var(--glass-size) / 2) at center);
  justify-content: flex-end;
  flex-direction: column;
  z-index: 1;
  opacity: 0.9;
  align-items: flex-end;
}

.WaveGlass__Fill {
  content: ' ';
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  transition: height 1000ms ease-in-out;
}

.WaveGlass__waves {
  position: relative;
  width: var(--glass-size);
  height: calc(var(--glass-size) / 5);
  min-height: calc(var(--glass-size) / 20);
  max-height: calc(var(--glass-size) / 5);
}

/* Animation */

.WaveGlass_parallax > use {
  animation: wave-move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.WaveGlass_parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.WaveGlass_parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.WaveGlass_parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.WaveGlass_parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes wave-move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@keyframes wave-grow {
  0% {
    height: 0px;
  }
  100% {
    height: var(--glass-size);
  }
}

.WaveGlass__Lotus .CenteredLogo {
  width: 15px;
  height: 15px;
}

.WaveGlass__Lotus .CenteredLogo__Petal svg path {
  fill: var(--accent-color);
}

.WaveGlass__Lotus {
  display: flex;
  content: ' ';
  flex-shrink: 0;
  z-index: 2;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 4s ease-in-out;
  position: absolute;
}

.WaveGlass__Lotus.FadeIn {
  opacity: 1;
}

.WaveGlass__Progress.Active {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.WaveGlass__Progress.Active .WaveGlass__Progress__Circle {
  transition: stroke-dashoffset 1s linear;
}
