@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-font-smoothing: aliased;
}

html.app {
  @apply select-none;
}

*:focus {
  outline: none !important;
}

.window-draggable {
  -webkit-app-region: drag;
}

.window-not-draggable {
  -webkit-app-region: no-drag;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.rounded-btn {
  @apply rounded-full px-4 py-2 bg-secondary text-white font-light duration-200 transition-colors;
}

.rounded-btn:hover {
  background: #9cbc9c;
}

.small-btn {
  @apply outline-none text-xs tracking-wider font-medium border border-v2-accent px-4 py-2 rounded-full uppercase bg-white text-v2-accent transition-colors;
}

.small-btn:hover {
  @apply bg-v2-accent bg-opacity-10;
}

.small-btn:focus {
  @apply outline-none;
}

.small-btn:active {
  @apply outline-none;
}

.rounded-btn:focus {
  @apply outline-none;
}

.rounded-btn:disabled {
  background: #b7cab7;
  @apply cursor-default;
}

.default-btn {
  @apply rounded-md px-4 py-2 bg-white text-black font-light duration-200 transition-colors;
}

.input-label {
  @apply text-white uppercase text-xs tracking-widest font-semibold mb-2;
}

.input-textarea {
  @apply w-full p-2 rounded-md shadow-md focus:outline-none text-black;
}

.login-social-button {
  @apply border rounded-full border-v2-login-border p-2 ml-5 flex justify-center items-center bg-v2-background transition-all duration-300 hover:shadow-md hover:border-v2-accent cursor-pointer active:bg-v2-accent active:bg-opacity-10 active:shadow-none;
  width: 42px;
  height: 42px;
}

.text-input {
  @apply ring-1 ring-gray-300 font-medium text-sm py-2 px-2 rounded-md outline-none focus:ring-v2-secondary focus:ring-opacity-50 transition-all duration-300;
}

.wrapper {
  @apply py-28 relative;
}

.-mx-18 {
  margin-left: -4.5rem;
  margin-right: -4.5rem;
}

.h-profile-modal {
  height: 650px;
  min-height: 650px;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
  -webkit-app-region: no-drag;
  pointer-events: auto;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--in-range:hover {
  @apply bg-v5-blue text-white;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-selecting-range:hover {
  @apply bg-v5-blue text-white bg-opacity-50 !important;
}

.react-datepicker {
  background-color: transparent;
  border: 0;
  @apply text-white;
}

.react-datepicker__header {
  background-color: transparent;
  border: 0;
}

.react-datepicker__current-month {
  @apply text-white;
}

.react-datepicker__day-name {
  @apply text-white;
  font-weight: bold;
  width: 3rem;
  margin-top: 1rem;
}

.react-datepicker__day {
  @apply text-white;
}

.react-datepicker__day--disabled {
  opacity: 0.6;
}

.react-datepicker__day--selected:hover {
  @apply bg-v5-blue;
  opacity: 0.8;
}

.react-datepicker__day:hover {
  @apply bg-v5-blue;
}

.react-datepicker__day {
  margin: 1em;
}

.react-datepicker__day--highlighted {
  @apply bg-v5-blue;
}

.centered-inline-datepicker > .react-datepicker-wrapper {
  width: auto;
}

.centered-inline-datepicker > .react-datepicker__tab-loop {
  @apply inline-block;
}

.app::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.app::-webkit-scrollbar-track {
  /* background: #202020; */
  background: transparent;
}

/* Handle */
.app.dark ::-webkit-scrollbar-thumb,
.dark-scrollbar ::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 3px;
}
.app::-webkit-scrollbar-thumb {
  background: rgb(222, 222, 222);
  border-radius: 3px;
}

/* Handle on hover */
.app::-webkit-scrollbar-thumb:hover {
  background: rgb(200, 200, 200);
}

.dark-scrollbar ::-webkit-scrollbar-thumb:hover,
.app.dark ::-webkit-scrollbar-thumb:hover {
  background: rgb(94, 94, 94);
}

/* For Session Reminder Time Picker */
.react-datepicker--time-only {
  font-size: 10px;
  border: 0;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.75);
  font-family: 'Open Sans', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

.react-datepicker-time__header {
  font-size: 10px;
}
.react-datepicker__header--time {
  padding-bottom: 4px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  margin-top: -4px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: auto;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #7e7e7e;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #656565;
}
