.animate-zoom-in-and-out {
  animation: zoom-in-and-out 4000ms ease-in-out infinite alternate;
}

@keyframes zoom-in-and-out {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

.message-from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #615eff;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

.message-from-me::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}
