html,
body {
  padding: 0;
  margin: 0;

  --primary-color: #111111;
  --secondary-color: #74ac7e;
  --tertiary-color: #949494;
  --accent-color: #2e4e43;
  --secondary-accent-color: #74ac7e;
  --secondary-accent-color: #c8e4be;
  --suggestions-background-color: #f7f7f7;
  --gray-background: rgba(240, 240, 240, 0.6);
  --background-color: #ffffff;
  --stroke-color: #eeeeee;

  font-family: 'Encode Sans', -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --normal-font-size: 16px;
  --small-font-size: 12px;
  --input-font-size: 12x;
  --hero-font-size: 24px;
  --big-font-size: 32px;
  --subheader-font-size: 14px;
}

body {
  overscroll-behavior-y: none;
}


@media (min-width: 768px) {
  .intercom-lightweight-app {
    display: initial;
  }
}

.prose {
  color: inherit !important;
}

.prose a,
.prose-lg a {
  text-underline-offset: 1px;
  text-decoration: underline;
}

.underline {
  text-underline-offset: 1px;
}

/* https://gist.github.com/IceCreamYou/cd517596e5847a88e2bb0a091da43fb4 */
.force-show-scrollbars ::-webkit-scrollbar-track:vertical {
  border-left: 1px solid #e7e7e7;
  box-shadow: 1px 0 1px 0 #f6f6f6 inset, -1px 0 1px 0 #f6f6f6 inset;
}

.force-show-scrollbars ::-webkit-scrollbar-track:horizontal {
  border-top: 1px solid #e7e7e7;
  box-shadow: 0 1px 1px 0 #f6f6f6 inset, 0 -1px 1px 0 #f6f6f6 inset;
}

.force-show-scrollbars ::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: #fafafa;
  width: 14px;
}

.force-show-scrollbars ::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #c1c1c1;
  border-color: transparent;
  border-radius: 9px 8px 8px 9px;
  border-style: solid;
  border-width: 3px 3px 3px 4px; /* Workaround because margins aren't supported */
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Unfortunately scrollbars can't use CSS transitions. Also, it's not possible
   to highlight the thumb when the scrollbar track is hovered without some
   JavaScript acrobatics; https://jsfiddle.net/QcqBM/6/ is a start, but you
   also have to check whether the element has a scrollbar and if so how wide
   it is. */
.force-show-scrollbars ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

nav img[alt='Sign up with Google'] {
  width: 185px;
  margin-top: -15px;
}
